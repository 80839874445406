const revenueFields = [
  {
    label: 'Junior Clients',
    name: 'nzone_jr_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Junior Sales',
    name: 'nzone_jr_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Youth Clients',
    name: 'nzone_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Youth Sales',
    name: 'nzone_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Adult Clients',
    name: 'nzone_adult_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Adult Sales',
    name: 'nzone_adult_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Camp Clients',
    name: 'nzone_camp_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Camp Sales',
    name: 'nzone_camp_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Community Clients',
    name: 'nzone_comm_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Community Sales',
    name: 'nzone_comm_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Misc Clients',
    name: 'misc_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Misc Sales',
    name: 'misc_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Recurring Clients',
    name: 'recurring_clients',
    type: 'number',
    value: null,
    totalGroup: 'Clients'
  },
  {
    label: 'Recurring Sales',
    name: 'recurring_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Additional Sales',
    name: 'additional_sales',
    type: 'money',
    value: null,
    totalGroup: 'Revenue'
  },
  {
    label: 'Deductions',
    name: 'deductions',
    type: 'money',
    deduction: true,
    value: null,
    totalGroup: 'Revenue'
  }
]
const totalGroups = [
  {
    label: 'Clients',
    type: 'number'
  },
  {
    label: 'Revenue',
    type: 'revenue'
  }
]
export default { revenueFields, totalGroups }