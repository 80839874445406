<template>
  <div>
    <b-form-group v-for="(field, index) in formData"
      :label="field.label"
      :label-for="field.name"
      label-cols="12"
      label-cols-md="4"
      :key="index"
    >
      <!-- textarea -->
      <b-form-textarea v-if="field.type === 'textarea'"
        :id="field.name"
        :required="typeof field.required === 'undefined' || field.required"
        v-model="formData[index].value"
      ></b-form-textarea>

      <template v-else>
        <!-- money -->
        <b-input-group v-if="field.type === 'money'" prepend="$">
          <b-form-input
            type="number"
            :id="field.name"
            step="0.01"
            :required="typeof field.required === 'undefined' || field.required"
            v-model="formData[index].value"
          ></b-form-input>
        </b-input-group>
        <!-- text -->
        <b-form-input v-else
          :type="field.type"
          :id="field.name"
          :required="typeof field.required === 'undefined' || field.required"
          v-model="formData[index].value"
        ></b-form-input>
      </template>

      <br v-if="field.type === 'money'" />
    </b-form-group>

    <b-row>
      <b-col offset-md="4">
        <div v-for="(group, index) in totalGroups" :key="index">
          Total {{ group.label }}: <span v-if="group.type && group.type === 'revenue'">$</span>{{ groupTotal(group.label) }}
        </div>
        <!-- b-btn type="submit" class="mt-4" block id="report-form-simple-submit">Submit</b-btn -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ReportFormSimple',
  props: {
    formFields: {
      type: Array,
      required:true
    },
    totalGroups: {
      type: Array,
      default () {
        return []
      }
    },
    formReference: String,
    formReference: String
  },
  data () {
    return {
      formData: [],
      totalGroupsData: {}
    }
  },
  computed: {
    formRef () {
      return this.formReference ? this.formReference : 'form-' + Math.floor(Math.random() * 1000) + 100
    }
  },
  methods: {
    groupTotal (group) {
      let total = 0, newVal = 0
      for (let i in this.formData) {
        newVal = parseFloat(this.formData[i].value)
        if ( !isNaN(newVal) && this.formData[i].totalGroup && this.formData[i].totalGroup === group ) {
          total = !this.formData[i].deduction ? (total + newVal) : (total - newVal)
        }
      }
      return total
    },
    emitTotals () {
      for (let i in this.totalGroups) {
        this.totalGroupsData[this.totalGroups[i].label] = this.groupTotal(this.totalGroups[i].label)
      }
      this.$emit('form-update', this.totalGroupsData)
    },
    onSubmit (e) {
      e.preventDefault()
      this.$emit('form-submit', this.formData)
    }
  },
  watch: {
    formData: {
      handler () {
        this.emitTotals()
      },
      deep: true
    }
  },
  mounted () {
    this.formData = this.formFields
    this.emitTotals()
  }
}
</script>
